import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "draft",
  "path": "/transplant-in-salt-lake-city/",
  "heroImage": "utah.jpg",
  "title": "A Salt Lake City Transplant",
  "description": "Moving to Salt Lake City for Work",
  "date": "2012-05-17T00:00:00.000Z",
  "tags": ["personal", "outdoor"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FadeIn = makeShortcode("FadeIn");
const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After living in California for 3 years, it was very different moving out to Salt Lake City for a new job.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.796875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABZiaq6JhP/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACAxQx/9oACAEBAAEFAlLQM4FJr60qzlyn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHhAAAgIABwAAAAAAAAAAAAAAAAECEQMhIjEycZH/2gAIAQEABj8C1RHnRyRh9lSimbP0/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARMSFhcYH/2gAIAQEAAT8hotvDsSg5bcocqgH5Y+AHLMizh6j/2gAMAwEAAgADAAAAEEDf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EMGwv//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxDUtb//xAAcEAEAAwEBAAMAAAAAAAAAAAABABEhMUFRYXH/2gAIAQEAAT8QDuX4Ba+IxE6LM/dlfdXE2GLe6jWPZfsb0Y8e1GifSSp//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mountains",
          "title": "Mountains",
          "src": "/static/b6e8bce268fab89a6f6f777aafd96cab/72e01/mountains.jpg",
          "srcSet": ["/static/b6e8bce268fab89a6f6f777aafd96cab/e4a55/mountains.jpg 256w", "/static/b6e8bce268fab89a6f6f777aafd96cab/36dd4/mountains.jpg 512w", "/static/b6e8bce268fab89a6f6f777aafd96cab/72e01/mountains.jpg 1024w", "/static/b6e8bce268fab89a6f6f777aafd96cab/ac99c/mountains.jpg 1536w", "/static/b6e8bce268fab89a6f6f777aafd96cab/5cbb4/mountains.jpg 1651w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I spent 3 years in Salt Lake City and it was a snowboarder's dream city.`}</p>
    <FadeIn mdxType="FadeIn">
  <Sidenote title="Hello World" content="<p>Poor old tree. I thought today we would do a happy little picture. Happy painting, God bless. There comes a nice little fluffer. Now then, let's play.</p><p>this it another paragraph.</p>" mdxType="Sidenote" />
    </FadeIn>
    <FadeIn mdxType="FadeIn">
  <Sidenote title="Hello World" content="<p>Poor old tree. I thought today we would do a happy little picture. Happy painting, God bless. There comes a nice little fluffer. Now then, let's play.</p><p>this it another paragraph.</p>" mdxType="Sidenote" />
    </FadeIn>
    <FadeIn mdxType="FadeIn">
  <Sidenote title="Hello World" content="<p>Poor old tree. I thought today we would do a happy little picture. Happy painting, God bless. There comes a nice little fluffer. Now then, let's play.</p><p>this it another paragraph.</p>" mdxType="Sidenote" />
    </FadeIn>
    <FadeIn mdxType="FadeIn">
  <Sidenote title="Hello World" content="<p>Poor old tree. I thought today we would do a happy little picture. Happy painting, God bless. There comes a nice little fluffer. Now then, let's play.</p><p>this it another paragraph.</p>" mdxType="Sidenote" />
    </FadeIn>
    <p>{`Just go out and talk to a tree. Make friends with it. Let the paint work. By now you should be quite happy about what's happening here. Just go back and put one little more happy tree in there. There we are. I guess that would be considered a UFO. A big cotton ball in the sky.`}</p>
    <p>{`Poor old tree. I thought today we would do a happy little picture. Happy painting, God bless. There comes a nice little fluffer. Now then, let's play.`}</p>
    <p>{`A little happy sunlight shining through there. If there's two big trees invariably sooner or later there's gonna be a little tree. Look at them little rascals. We might as well make some Almighty mountains today as well, what the heck.`}</p>
    <p>{`If you didn't have baby clouds, you wouldn't have big clouds. We'll play with clouds today. You don't have to spend all your time thinking about what you're doing, you just let it happen.`}</p>
    <p>{`That's a crooked tree. We'll send him to Washington. If what you're doing doesn't make you happy - you're doing the wrong thing. Everyone is going to see things differently - and that's the way it should be. If we're gonna walk though the woods, we need a little path. It just happens - whether or not you worried about it or tried to plan it.`}</p>
    <p>{`Nothing wrong with washing your brush. Volunteering your time; it pays you and your whole community fantastic dividends. There's nothing wrong with having a tree as a friend. You have to make these big decisions. Only God can make a tree - but you can paint one. This is the fun part`}</p>
    <FadeIn mdxType="FadeIn">Hello</FadeIn>
    <p>{`Maybe there's a happy little bush that lives right there. I'm gonna start with a little Alizarin crimson and a touch of Prussian blue The light is your friend. Preserve it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      